var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welfare"},[(_vm.fullWidth < 767)?_c('div',[_c('Banner',{attrs:{"bannerimg":_vm.staticUrl +
          (_vm.topBanner['cover_mobile_' + _vm.$i18n.locale]
            ? _vm.topBanner['cover_mobile_' + _vm.$i18n.locale]
            : _vm.topBanner.cover_mobile)}},[_c('p',{domProps:{"innerHTML":_vm._s(
          (_vm.topBanner['description_mobile_' + _vm.$i18n.locale]
            ? _vm.topBanner['description_mobile_' + _vm.$i18n.locale]
            : _vm.topBanner.description_mobile) == ''
            ? _vm.topBanner['description_' + _vm.$i18n.locale]
              ? _vm.topBanner['description_' + _vm.$i18n.locale]
              : _vm.topBanner.description
            : _vm.topBanner['description_mobile_' + _vm.$i18n.locale]
            ? _vm.topBanner['description_mobile_' + _vm.$i18n.locale]
            : _vm.topBanner.description_mobile
        )}})])],1):_c('div',[_c('Banner',{attrs:{"bannerimg":_vm.staticUrl +
          (_vm.topBanner['cover_' + _vm.$i18n.locale]
            ? _vm.topBanner['cover_' + _vm.$i18n.locale]
            : _vm.topBanner.cover)}},[_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.topBanner['description_' + _vm.$i18n.locale]
            ? _vm.topBanner['description_' + _vm.$i18n.locale]
            : _vm.topBanner.description
        )}})])],1),_c('div',{staticClass:"welfare-list container"},[_c('ul',_vm._l((_vm.welfareList),function(item){return _c('li',{key:_vm.$t(item.title),staticClass:"welfare-item"},[_c('div',{staticClass:"item-img"},[_c('img',{attrs:{"src":item.img,"alt":""}})]),_c('div',{staticClass:"item-text"},[_c('p',[_vm._v(_vm._s(_vm.$t(item.title)))]),_c('p',[_vm._v(_vm._s(_vm.$t(item.text)))])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }