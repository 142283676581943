<template>
  <div class="common-banner container-fuild">
    <div class="img-box">
      <img :src="bannerimg" alt="" srcset="" />
      <div class="text-box">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    bannerimg: {
      type: String,
      required: true
    }
  }
};
</script>

<style>
.common-banner .img-box {
  height: 100%;
  position: relative;
}
.common-banner .img-box img {
  width: 100%;
  height: 100%;
}
.common-banner .img-box .text-box {
  position: absolute;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}
.common-banner .img-box .text-box p {
  font-size: 34px;
}
@media only screen and (max-width: 767px) {
  .common-banner .img-box .text-box p {
    font-size: 15px;
  }
  .common-banner .img-box img {
    width: 100%;
    height: 100%;
  }
}
</style>
