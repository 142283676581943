<template>
  <div class="welfare">
    <div v-if="fullWidth < 767">
      <Banner
        :bannerimg="
          staticUrl +
            (topBanner['cover_mobile_' + $i18n.locale]
              ? topBanner['cover_mobile_' + $i18n.locale]
              : topBanner.cover_mobile)
        "
      >
        <p
          v-html="
            (topBanner['description_mobile_' + $i18n.locale]
              ? topBanner['description_mobile_' + $i18n.locale]
              : topBanner.description_mobile) == ''
              ? topBanner['description_' + $i18n.locale]
                ? topBanner['description_' + $i18n.locale]
                : topBanner.description
              : topBanner['description_mobile_' + $i18n.locale]
              ? topBanner['description_mobile_' + $i18n.locale]
              : topBanner.description_mobile
          "
        ></p>
      </Banner>
    </div>
    <div v-else>
      <Banner
        :bannerimg="
          staticUrl +
            (topBanner['cover_' + $i18n.locale]
              ? topBanner['cover_' + $i18n.locale]
              : topBanner.cover)
        "
      >
        <p
          v-html="
            topBanner['description_' + $i18n.locale]
              ? topBanner['description_' + $i18n.locale]
              : topBanner.description
          "
        ></p>
      </Banner>
    </div>
    <!-- list -->
    <div class="welfare-list container">
      <ul>
        <li
          class="welfare-item"
          v-for="item in welfareList"
          :key="$t(item.title)"
        >
          <div class="item-img"><img :src="item.img" alt="" /></div>
          <div class="item-text">
            <p>{{ $t(item.title) }}</p>
            <p>{{ $t(item.text) }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Banner from "../../components/Banner";
import request from "../../api/request";
export default {
  name: "culture",
  components: {
    Banner,
  },
  data() {
    return {
      topBanner: {},
      staticUrl: request.staticUrl,
      fullWidth: 0,
      welfareList: [
        {
          img: require("../../assets/img/ico_ico01_welfare.png"),
          title: "No tie! 편안한 복장",
          text: "자율 복장으로 출퇴근길을 가볍게~!",
        },
        // {
        //   img: require("../../assets/img/ico_ico02_welfare.png"),
        //   title: "사내 카페테리아 운영",
        //   text: "카페테리아, 미니 피트니스 등 휴게 공간",
        // },
        // {
        //   img: require("../../assets/img/ico_ico03_welfare.png"),
        //   title: "해피 급여 데이",
        //   text: "급여일에 한해 점심시간 30분 연장",
        // },
        {
          img: require("../../assets/img/ico_ico04_welfare.png"),
          title: "명절 선물 지급",
          text: "설,추석 명절에 백화점 상품권 지급",
        },
        {
          img: require("../../assets/img/ico_ico05_welfare.png"),
          title: "자율 연차제",
          text: "근로기준법에 따른 연차 제도 실시",
        },
        // {
        //   img: require("../../assets/img/ico_ico06_welfare.png"),
        //   title: "시차 출퇴근제",
        //   text: "출근10:00 ~ 10:30 | 퇴근 19:00 ~ 19:30",
        // },
        {
          img: require("../../assets/img/ico_ico07_welfare.png"),
          title: "야간 근무자 지원",
          text: "야근 식대, 야근 택시비",
        },
        {
          img: require("../../assets/img/ico_ico08_welfare.png"),
          title: "각종 경조사 지원",
          text: "경조금 및 경조 휴가",
        },
        // {
        //   img: require("../../assets/img/ico_ico09_welfare.png"),
        //   title: "패밀리데이",
        //   text: "매월 셋째주 금요일 17시 조기 퇴근",
        // },
        {
          img: require("../../assets/img/ico_ico10_welfare.png"),
          title: "인재 추천 보상금",
          text: "추천 인재 정규직 전환 시 추천자에게 직급에 따른 보상 지급",
        },
        {
          img: require("../../assets/img/ico_ico11_welfare.png"),
          title: "회식비 지원",
          text: "팀원 단합을 위한 월 단위 회식비 지원",
        },
        // {
        //   img: require("../../assets/img/ico_ico12_welfare.png"),
        //   title: "생일 축하 및 선물 지급",
        //   text: "백화점 상품권 및 유급 휴가 0.5일",
        // },
        {
          img: require("../../assets/img/icon01.png"),
          title: "네이버 페이",
          text: "Naver pay",
        },
        {
          img: require("../../assets/img/icon02.png"),
          title: "점심 식대",
          text: "Lunch price",
        },
      ],
    };
  },
  mounted() {
    this.banners();
    this.handleResize();
  },
  methods: {
    banners() {
      let _this = this;
      request
        .get("/banners", {
          banner_type__in: "6",
        })
        .then(function(res) {
          if (res.status == 200) {
            _this.topBanner = res.data.results[0];
          }
        });
    },
    handleResize(event) {
      this.fullWidth = document.documentElement.clientWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
.welfare {
}
.welfare .welfare-list {
  margin: 100px auto;
}
.welfare .welfare-list ul {
  width: 100%;
  overflow: hidden;
  padding: 10px 0px;
  display: flex;
  display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
  display: -moz-box; /* Firefox 17- */
  display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
  display: -moz-flex; /* Firefox 18+ */
  display: -ms-flexbox; /* IE 10 */
  display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
  -webkit-flex-wrap: wrap;
  -webkit-align-items: left;
  -webkit-justify-content: left;
}
.welfare .welfare-list ul .welfare-item {
  width: calc(33% - 30px);
  padding: 20px 0px 10px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: left;
}
.welfare .welfare-list ul .welfare-item .item-img {
  width: 140px;
  height: 140px;
  margin: 20px auto;
}
.welfare .welfare-list ul .welfare-item .item-text {
  text-align: center;
}
.welfare .welfare-list ul .welfare-item .item-text p {
  margin: 30px 0;
}
.welfare .welfare-list ul .welfare-item .item-text p:nth-child(1) {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
}
.welfare .welfare-list ul .welfare-item .item-text p:nth-child(2) {
  color: #979797;
  font-size: 12px;
}
@media only screen and (max-width: 767px) {
  .welfare .welfare-list ul {
    flex-wrap: wrap;
  }
  .welfare .welfare-list ul .welfare-item {
    flex-grow: 1;
    width: calc(calc(100% / 2) - 40px) !important;
  }
  .welfare .welfare-list {
    margin: 20px 0;
  }
  .welfare .welfare-list ul .welfare-item .item-img {
    width: 140px;
    height: 140px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .welfare .welfare-list ul .welfare-item .item-text p {
    margin: 0 0;
  }
}
</style>
